import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import vision from "vision.jpeg"
// Import Swiper styles
import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
import arrswp from "../db/swp"
// import './styles.css';

// import required modules
import { Autoplay, Mousewheel } from 'swiper/modules';
// import Maroc from "maroc.jpg"
// import meta from "meta.jpeg"
// import clts from "clts.jpeg"
// import logo from "logo.png"
export default function Swipper() {
    return (
        <div className='swp_container'>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: false,
                }}
                mousewheel={true}
                // navigation={true}
                modules={[Autoplay, Mousewheel]}
                className="mySwiper"
            >
                {arrswp.map((val)=>(
                    <SwiperSlide key={val.key}>
                        <div><img src={val.src} alt ="swppic"/></div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
