import React from 'react'
import founderdata from "../db/foundersdata"
import Linkdin from "../pic/linkedin.svg"
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer';

function Founders() {
    const { ref: refrin, inView: checkfnd } = useInView({
        triggerOnce: true
    });
    return (
        <div id='Founders' className='Founders'>
            <h1 ref={refrin} className={checkfnd ? "title_found" : "none"}>Founder s Board</h1>
            <div ref={refrin} id='flexcontact' >
                {
                    founderdata.map((val) => (
                        <div key={val.key} className='cont_pic'>
                            <img className='pic' src={val.pic} alt='pic' />
                            <h3>{val.name}</h3>
                            <div className='m_link'>
                                <span>{val.mission}</span>
                                <Link to={val.linkdin}><img className='linkdn' src={Linkdin} alt='linkdin' /></Link>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Founders
