import Handshake from "../pic/handshake.svg"
import creative from "../pic/creativity.svg"
import rep from "../pic/mobile-shopping.svg"
import tshirt from "../pic/tshirt.svg"
const vp = [
    {
        key:1,
        src: tshirt ,
        title:"UNIQUE AND CREATIVE CLOTHING.",
        description:`write somthin her ...`
    },
    {
        key:2,
        src:Handshake,
        title:"RESPECTS PURCHASING POWER AND MEETS NEEDS",
        description:`write somthin her ...`
    },
    {
        key:3,
        src: rep,
        title:"FACILITATING THE READY-TO-WEAR PURCHASING SERVICE",
        description:`write somthin her ...`
    },
    {
        key:4,
        src: creative,
        title:"POWER TO VIRTUALLY TRY OUR PRODUCTS",
        description:`write somthin her ...`
    }
]
export  default vp