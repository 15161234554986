import React, { Suspense } from 'react'
// import Splin from '../component/Splin'
// import Earth from "../pic/bg_one.jpeg"
// import logo from "../pic/xp.png"
import logo from "../pic/phenix.png"
import gf from "../pic/Dd.gif"
import { useInView } from 'react-intersection-observer';
// import {Link} from "react-router-dom"
function About() {
    // className={`sec_one ${isVis ? "isVis" : "none"}`} ref={myRef}
    const { ref: myRef, inView: isVis } = useInView({
        triggerOnce:true
    });
    return (
        <div  ref={myRef} className={isVis ? "About" : "none"} id='Who'>
            {/* <img src={Earth} alt="earth" className='earth' /> */}
            <div className='img_cont'>
                <Suspense>
                    <img src={gf} alt="gif" className='gif' />
                    <img src={logo} alt="logo" className='logo' />
                </Suspense>
            </div>
            <div className='intro'>
                <div>
                    <h1>Who ARE WE <span className='sym'>?</span></h1>
                    <p>
                        We are Xphinix, a 100% digital ready-to-wear brand,
                        We aim to provide a new and unique shopping experience in
                        Selling clothes and facilitating their purchase online
                        and this is by allowing our customers to try our products virtually before purchasing ,
                        and also our website or a mobile apllication are going to represent our product with a deffrent
                        way by dependin to 3D models and a many other features
                        <br />
                        at xphinix we strive to develop and to add a new touch to this market and
                        also we gonna work to be more close to our costumers and to thier need
                    </p>
                </div>
                {/* <div className='Circle'>
                    <Link to='https://www.linkedin.com/company/x-phinix/?viewAsMember=true'></Link>
                </div> */}
            </div>
        </div>
    )
}

export default About
