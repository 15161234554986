import one from "../pic/vr_privacy.jpg"
import two from "../pic/wmn.png"
import three from "../pic/imge.png"

const arrswp = [
    {
        key:1,
        src:one
    },
    {
        key:2,
        src:two
    },
    {
        key:3,
        src:three
    }
]

export default arrswp
