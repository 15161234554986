import React, { useState  , useRef , useEffect} from 'react'
import logo from "../pic/logo.png"
// import {Link} from "react-router-dom"
const Menu = ()=>{
  return(
    <div className='mn'>
      <a href="#Who">Who Are WE</a>
      <a href="#Vision">Our Vision</a>
      <a href="#Value">Our Value</a>
      <a href="#Founders">Our Team</a>
      <a href="#Contact">Contact</a>
    </div>
  )
}

function Nav() {
  const [menu, setMenu] = useState(false);
  const Mynav = useRef();

  const handleOpen = (e) => {
    e.stopPropagation();
    setMenu(!menu);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Get the scroll position
      const scrollPosition = window.scrollY;
      // Check if scroll position is greater than or equal to 100vh
      if (scrollPosition > window.innerHeight) {
        // If scrolled past 100vh, add sticky class to nav
        Mynav.current.classList.add('sticky');
      } else {
        // If not, remove sticky class from nav
        Mynav.current.classList.remove('sticky');
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Run effect only once on component mount

  return (
    <div ref={Mynav}>
      <nav  className='flex justify-between ml-10 mr-10'>
        <div className='logo'>
          <img src={logo} alt='logo' />
        </div>
        <div onClick={(e)=>handleOpen(e)} className='burger w-10 h-auto cursor-pointer'>
          <div className='line border border-purple-400 h-1 bg-transparent m-1 w-1/3'></div>
          <div className='line border border-purple-400 h-1 bg-transparent m-1'></div>
          <div className='line border border-purple-400 h-1 bg-transparent m-1 ml-auto w-1/3'></div>
        </div>
      </nav>
      {menu && <Menu />}
    </div>
  );
}

export default Nav;

