import React from 'react';
import Swipper from '../component/Swipper';
import { useInView } from 'react-intersection-observer';
// import Titleboard from '../component/Titleboard';
import vp from "../db/vp";

function Vs() {
    const { ref: swipperRef, inView: swipperInView } = useInView({
        triggerOnce: true
    });
    const { ref: myelem, inView: visionInView } = useInView({
        triggerOnce: true
    });
    const { ref: vpRef, inView: vpInView } = useInView({
        triggerOnce: true
    });
    // const { ref: devs, inView: devsView } = useInView();

    return (
        <div id='Vision' className='Vision'>
            <div className='nested_vision'>
                {/* <img src={vision} alt="vision"/> */}
                <div ref={swipperRef} className={swipperInView ? "div_swp" : "none"} >
                    <Swipper />
                </div>
                <div className='Vision_cards'>
                    <div ref={myelem}>
                        <h1 className={visionInView ? "honeheader" : "none"}>VISION</h1>
                        <p className={visionInView ? "p_anime" : "none"}>Our vision as a brand is to make buying clothes online more
                            convenient and enjoyable by creating an online store that can offer our
                            customers more choice and a new experience, and all this using the models
                            "3D" and the "AR" to virtually try on our clothes before buying them,
                            and this to facilitate the purchase of clothes for our customers, and
                            to meet their expectations as well as the satisfaction of their needs </p>
                        <span className='tag'>#Live The Future</span>
                    </div>
                </div>
            </div>
            {/* <div>
                <Titleboard text="Xphinix"/>
            </div> */}
            <div id='Value' ref={vpRef} className={vpInView ? 'Vp' : "none"}>
                <h1 ref={vpRef} className={vpInView ? 'Vp' : "none"}>our value proposition</h1>
                <div className="vp_cont">
                    {
                        vp.map((val) => (
                            <div className="dv_cont" key={val.key}>
                                <img src={val.src} alt="pics" />
                                <h3>{val.title}</h3>
                                <p>{val.description}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Vs;
