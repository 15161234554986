import React from 'react'
import xp from "../pic/xp.png"

function Footer() {
  const date = new Date()
  const y = date.getFullYear();
  return (
    <footer>
      <img src={xp} alt="logo"/>
      <p>XPHINIX&copy;{y}</p>
    </footer>
  )
}

export default Footer
