import ash from "../pic/ash.png"
import hexa from "../pic/hexa.png"
import aym from "../pic/aym.png"
// import { keyboard } from "@testing-library/user-event/dist/keyboard"

const founderdata = [
    {key:1,name:"Haitam Abdeladim",pic:hexa,mission:"CEO",linkdin:"https://www.linkedin.com/in/haitam-abdeladim-88a4022b8/"},
    {key:2,name:"Aymen Ouzali",pic:aym,mission:"COO",linkdin:"https://www.linkedin.com/in/aymane-ouzali-9b00201a1/"},
    {key:3,name:"Achraf Abdeladim",pic:ash,mission:"CTO",linkdin:"https://www.linkedin.com/in/achraf-abdeladim-68b764235/"}
]
export default founderdata