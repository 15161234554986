import React from 'react'
import Nav from './Nav'
import First from '../pages/First'
import Abt from '../pages/Abt'
import Vs from '../pages/Vs'
// import Goals from '../pages/Goals'
import Founders from '../pages/Founders'
import Invest from './Invest'
import Footer from '../pages/Footer'

function Main() {
    return (
        <div>
            <Nav />
            <First />
            <Abt />
            <Vs/>
            <Founders/>
            <Invest/>
            <Footer/>
            {/* <Goals/> */}
        </div>
    )
}

export default Main
