import React, { useState, useEffect, Suspense } from 'react'

function Message(props) {
    const [messages, setMessages] = useState([ "xphinix", "Live the future"]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentWord, setCurrentWord] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

    const stylin = {
        background: "linear-gradient(120deg, rgb(163 100 205 / 93%), #aba9ad ,#471e664a)",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        color: "transparent",
        textTransform: "capitalize"
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const message = messages[currentIndex];
            if (!isDeleting) {
                if (currentWord.length < message.length) {
                    setCurrentWord(prevWord => prevWord + message[currentWord.length]);
                } else {
                    setIsDeleting(true);
                }
            } else {
                if (currentWord.length > 0) {
                    setCurrentWord(prevWord => prevWord.slice(0, -1));
                } else {
                    setCurrentIndex(prevIndex => (prevIndex + 1) % messages.length);
                    setIsDeleting(false);
                }
            }
        }, 200); // Adjust speed as needed

        return () => clearInterval(intervalId); // Clean up interval on component unmount
    }, [messages, currentIndex, currentWord, isDeleting]);

    return (
        <div className={props.class}>
            <h1 style={stylin}>{currentWord || '|'}</h1>
        </div>
    );
}

function First() {
    return (
        <div className=' first grid content-center justify-center' >
            <Suspense>
                <Message class='text-7xl font-extrabold  block text-center' />
            </Suspense>
            <p className=' p-5 capitalize'>Where innovation meets inspiration, forging Pathways to a Brighter tomorrow.</p>
            <button>#Live the Future</button>
        </div>
    )
}

export default First
