import React, { useState } from 'react';
import Linkdin from '../pic/linkedin.svg';
// import { Link } from 'react-router-dom';

function Invest() {
    const [formData, setFormData] = useState({
        from: '',
        subject: '',
        text: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://xp-rep-backs.onrender.com/email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                console.log('Email sent successfully');
                // Optionally, reset the form
                setFormData({
                    from: '',
                    subject: '',
                    text: ''
                });
            } else {
                console.error('Failed to send email');
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    return (
        <div id='Contact' className="contact_container">
            <h1>Contact us</h1>
            <p></p>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Full Name:</label>
                    <input
                        type="text"
                        name="from"
                        value={formData.from}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div id="email" className="spantwo">
                    <label>Email address:</label>
                    <input
                        type="email"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                    />
                </div>
                <textarea
                    placeholder="Enter your Message"
                    className="spantwo"
                    name="text"
                    value={formData.text}
                    onChange={handleChange}
                    required
                ></textarea>
                <button type="submit" className="button-17">
                    Join us
                </button>
            </form>
            <a href="https://www.linkedin.com/company/xphinix/">
                <img className="linkdn" src={Linkdin} alt="linkedin" />
            </a>
        </div>
    );
}

export default Invest;
